import * as React from 'react'
import loadable from '@loadable/component'
import './styles.scss'

const Required = loadable(() =>
    import('/src/components/Form/Required/Required')
)

const RequiredText = () => {
    return (
        <small className="c-required-text">
            <Required /> These fields are required
        </small>
    )
}

export default RequiredText
